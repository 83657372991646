import React, { useRef, useState } from 'react';
import { Button, Card, Container, Row, Col, Spinner} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { toast, ToastContainer } from 'react-toastify';
import ExcelIcon from '../../Assets/ExcelIcon.svg';
import * as XLSX from "xlsx";

const TelecallingDefaulter = () => {

  const [error, setError]= useState("");
  const [jsonData, setJsonData] = useState([]);
  const [excelFile, setExcelFile] = useState(null);
  const fileInputRef = useRef(null);
  const [loader, setLoader] = useState(false);


  const requiredColumns = [
    "propertyCode", "propertyKey", "propertyContactNo", "propertyName", "propertyAddress", 
    "zonename", "gatname", "arrearsAmount", "currentBill", "totalAmount", "finalUserType", "financialYear", "dataFilter"
  ];


  const handleFileUpload = async (event) => {

    const file = event.target.files[0];
    if (!file) return;

        // Check file type
    const validExtensions = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
    const validFileExtensions = ['.xls', '.xlsx'];

    const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();

    if (!validExtensions.includes(file.type) && !validFileExtensions.includes(fileExtension)) {
        setError("Please upload an Excel file.");
        return;
    }

    try {
      setExcelFile(event.target.files[0]);
      const result = await readExcel(file);
      setJsonData(result); // Store data to send to API
      setError("");
    } catch (err) {
        setError(err);
        console.error(err);
    }
    finally{
      if (fileInputRef.current) {
          fileInputRef.current.value = ""; // ✅ Reset before processing
        }
    }
  };



const readExcel = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      // Check if all required columns are present
      const sheetColumns = Object.keys(jsonData[0] || {});
      const missingColumns = requiredColumns.filter(col => !sheetColumns.includes(col));

      if (missingColumns.length > 0) {
        return reject(`Missing columns: ${missingColumns.join(', ')}`);
      }

      let validatedData = [];
      let errors = [];

      for (let index = 0; index < jsonData.length; index++) {
        const row = jsonData[index];
        let validatedRow = {};

        // Populate all required columns, default to "" if missing
        requiredColumns.forEach((col) => {
          validatedRow[col] = row[col] !== undefined ? row[col] : "";
        });

        // Validate propertyCode (must have two decimal places)
        if (!/^-?\d+(\.\d{2})?$/.test(validatedRow.propertyCode)) {
          errors.push(`Row ${index + 2}: propertyCode must have two decimal places.`);
        }

        // Validate financialYear (must be 2024-25)
        if (validatedRow.financialYear !== "2024-25") {
          errors.push(`Row ${index + 2}: financialYear must be '2024-25'.`);
        }

        // Validate dataFilter (must be कॉलिंग or स्थापत्य)
        if (validatedRow.dataFilter !== "कॉलिंग" && validatedRow.dataFilter !== "स्थापत्य") {
          errors.push(`Row ${index + 2}: dataFilter must be 'कॉलिंग' or 'स्थापत्य'.`);
        }

        validatedData.push(validatedRow);
      }

      if (errors.length > 0) {
        return reject(errors.join('\n'));
      }

      resolve({ data: validatedData });
    };

    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};


const handleRemoveFile = ()=>{
  if (fileInputRef.current) {
    fileInputRef.current.value = ""; // ✅ Reset before processing
  }
  setExcelFile(null);
}

const submitTeleCalling = async()=>{
  if(!excelFile){
    setError("Please upload excel file");
    return;
  }
  try{
    setLoader(true);
    const res = await fetch(process.env.REACT_APP_BASE_URL + '/tc/updateTelecallingData', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonData),
    })

    const response = await res.json();
    if(response.message === "Telecalling data updated successfully"){
      toast.success("Telecalling data updated successfully");
    }
  }
  catch(Err){
    console.log("Err while telecalling defaulter", Err);
    toast.error("Failed, please try again");
  }
  finally{
    setLoader(false);
    setExcelFile(null);
  }
}
 

  return (
    <div className='TelecallingDefaulter'>
         <ToastContainer />
        <Container>
            <div className='d-flex justify-content-center align-items-center'>
              <h3 className='mb-0'>TeleCalling Defaulter (Internal Dev)</h3>
            </div>

            <Row className='justify-content-end me-4'>
              <Button
                href="/Telecalling_Sample_Excel.xlsx" // Make sure the file is inside the "public" folder
                download="Telecalling_Sample_Excel.xlsx"
                className="btn-submit ms-auto w-auto">
                <Icon.ArrowDownCircle className='me-2'/> Download Sample Excel
              </Button>
            </Row>

            <Row className='justify-content-center my-4'>
              <Col md={5}>
                <Card>
                    <Card.Header className='py-3'>
                      <h5 className='text-center mb-0'>Upload Bulk Excel</h5>
                    </Card.Header>

                    <Card.Body>
                    <Row className='mt-4 mb-3 justify-content-center'>
                <Col xs="auto">
                 {
                  excelFile!==null ?(
                    <Card className='uploadedFile text-center px-3 py-3'>
                      <img src={ExcelIcon} width={40} className='mx-auto mb-2'/>
                      <p className='mb-0'>{excelFile?.name}</p>
                      <Icon.XCircleFill className='removeIcon' size={18}
                          onClick={handleRemoveFile} />
                    </Card>
                 ) : 
                 <>
                    <input type="file" id="fileInput"
                     ref={fileInputRef} 
                      accept=".xlsx, .xls"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileUpload(e)}/>

                      <label htmlFor="fileInput" className="uploadFile" style={{cursor:'pointer'}}>
                        <Icon.CloudUpload className='me-2'/>Click here to upload file
                      </label>

                      {error && <p style={{ color: "red" }}>{error}</p>}
                  </>
                }
                     </Col>
                </Row>
                    </Card.Body>

                    <Card.Footer>
                      {
                        loader ? <center><Spinner /></center> :
                      <Button className='btn-submit ms-auto' onClick={submitTeleCalling}>
                          Submit
                      </Button>
                      }
                    </Card.Footer>
                </Card>
              </Col>
            </Row>


            </Container>
    </div>
  )
}

export default TelecallingDefaulter;